import { Suspense, lazy, ReactNode } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import AuthorizeGuard from './components/AuthorizeGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import Welcome from './components/dashboard/Welcome';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import { azureAdGroups } from './config';

interface RouteDefinition {
  path: string;
  element?: JSX.Element;
  groups?: string[];
  children?: RouteDefinition[];
  childrenGroups?: string[];
}

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Support
const SignOrdersSearch = Loadable(
  lazy(() => import('./pages/dashboard/signOrder/SignOrdersSearch'))
);
const IssuesOverview = Loadable(
  lazy(() => import('./pages/dashboard/signOrder/IssuesOverview'))
);
const DecodeReference = Loadable(
  lazy(() => import('./pages/dashboard/signOrder/DecodeReference'))
);
const SignOrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/signOrder/SignOrderDetails'))
);
const UpdateSignOrder = Loadable(
  lazy(() => import('./pages/dashboard/signOrder/UpdateSignOrder'))
);
const UpdateSigner = Loadable(
  lazy(() => import('./pages/dashboard/signOrder/UpdateSigner'))
);

// Branding
const BrandingAdmin = Loadable(
  lazy(() => import('./pages/dashboard/BrandingAdmin'))
);

// Customers
const CustomerSearch = Loadable(
  lazy(() => import('./pages/dashboard/customer/CustomerSearch'))
);
const CustomerDetails = Loadable(
  lazy(() => import('./pages/dashboard/customer/CustomerDetails'))
);
const CustomerCreate = Loadable(
  lazy(() => import('./pages/dashboard/customer/CustomerCreate'))
);
const CustomerUpdate = Loadable(
  lazy(() => import('./pages/dashboard/customer/CustomerUpdate'))
);

// Users
const UserSearch = Loadable(
  lazy(() => import('./pages/dashboard/user/UserSearch'))
);
const UserDetails = Loadable(
  lazy(() => import('./pages/dashboard/user/UserDetails'))
);
const UserCreate = Loadable(
  lazy(() => import('./pages/dashboard/user/UserCreate'))
);
const UserUpdate = Loadable(
  lazy(() => import('./pages/dashboard/user/UserUpdate'))
);

// Approvers
const ApproverSearch = Loadable(
  lazy(() => import('./pages/dashboard/approver/ApproverSearch'))
);
const ApproverDetails = Loadable(
  lazy(() => import('./pages/dashboard/approver/ApproverDetails'))
);
const ApproverCreate = Loadable(
  lazy(() => import('./pages/dashboard/approver/ApproverCreate'))
);
const ApproverUpdate = Loadable(
  lazy(() => import('./pages/dashboard/approver/ApproverUpdate'))
);

// Sign testing
const OrderWithLoginCreate = Loadable(
  lazy(() => import('./pages/dashboard/OrderWithLoginCreate'))
);

// Scheduled Tasks
const ScheduledTasksList = Loadable(
  lazy(() => import('./pages/dashboard/scheduledTasks/ScheduledTasksList'))
);

// Anonymization
const SignOrdersBatchAnonymization = Loadable(
  lazy(() => import('./pages/dashboard/anonymization/SignOrdersBatchAnonymization'))
);

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const Unauthorized = Loadable(lazy(() => import('./pages/Unauthorized')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const Home = Loadable(lazy(() => import('./pages/Home')));

const requireGroups = (groups: string[], component: ReactNode) => (
  <AuthorizeGuard groups={groups}>
    <>{component}</>
  </AuthorizeGuard>
);

const applyRequiredGroupsToRoutes = (
  routes: RouteDefinition[],
  requiredGroups?: string[]
): PartialRouteObject[] => {
  const a: PartialRouteObject[] = [];
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const p: PartialRouteObject = {
      path: route.path,
      element: route.element,
      children: route.children,
    };

    const { element, groups } = route;
    const parentGroups = requiredGroups || [];
    const ownGroups = groups || [];
    const allGroups = parentGroups.concat(ownGroups);
    if (allGroups.length > 0 && element) {
      p.element = requireGroups(allGroups, element);
    }

    if (route.children && route.children.length > 0) {
      const childrenGroups = route.childrenGroups || [];
      const parentAndChildrenGroups = parentGroups.concat(childrenGroups);
      p.children = applyRequiredGroupsToRoutes(
        route.children,
        parentAndChildrenGroups
      );
    }

    a.push(p);
  }
  return a;
};

const routeDefinitions: RouteDefinition[] = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Welcome />,
      },
      {
        path: 'branding',
        childrenGroups: [azureAdGroups.branding],
        children: [
          {
            path: 'brandingadmin',
            element: <BrandingAdmin />,
          },
        ],
      },
      {
        path: 'support',
        childrenGroups: [azureAdGroups.support],
        children: [
          {
            path: 'signorders',
            children: [
              {
                path: 'search',
                element: <SignOrdersSearch />,
              },
              {
                path: 'issues-overview',
                element: <IssuesOverview />,
              },
              {
                path: 'decode-reference',
                element: <DecodeReference />,
              },
              {
                path: ':signOrderId',
                children: [
                  {
                    path: 'details',
                    element: <SignOrderDetails />,
                  },
                  {
                    path: 'update',
                    element: <UpdateSignOrder />,
                  },
                  {
                    path: 'signers',
                    children: [
                      {
                        path: ':signerReference',
                        children: [
                          {
                            path: 'update',
                            element: <UpdateSigner />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'customers',
        childrenGroups: [azureAdGroups.customers],
        children: [
          {
            path: 'search',
            element: <CustomerSearch />,
          },
          {
            path: 'create',
            element: <CustomerCreate />,
          },
          {
            path: ':customerId',
            children: [
              {
                path: 'details',
                element: <CustomerDetails />,
              },
              {
                path: 'update',
                element: <CustomerUpdate />,
              },
            ],
          },
        ],
      },
      {
        path: 'users',
        childrenGroups: [azureAdGroups.users],
        children: [
          {
            path: 'search',
            element: <UserSearch />,
          },
          {
            path: 'create',
            element: <UserCreate />,
          },
          {
            path: ':userId',
            children: [
              {
                path: 'details',
                element: <UserDetails />,
              },
              {
                path: 'update',
                element: <UserUpdate />,
              },
            ],
          },
        ],
      },
      {
        path: 'approvers',
        childrenGroups: [azureAdGroups.approvers],
        children: [
          {
            path: 'search',
            element: <ApproverSearch />,
          },
          {
            path: 'create',
            element: <ApproverCreate />,
          },
          {
            path: ':approverId',
            children: [
              {
                path: 'details',
                element: <ApproverDetails />,
              },
              {
                path: 'update',
                element: <ApproverUpdate />,
              },
            ],
          },
        ],
      },
      {
        path: 'test',
        childrenGroups: [azureAdGroups.test],
        children: [
          {
            path: 'sign',
            element: <OrderWithLoginCreate />,
          },
        ],
      },
      {
        path: 'scheduled-tasks',
        childrenGroups: [azureAdGroups.tasks],
        children: [
          {
            path: 'list',
            element: <ScheduledTasksList />,
          },
        ],
      },
      {
        path: 'anonymization',
        childrenGroups: [azureAdGroups.forms],
        children: [
          {
            path: 'signorders-batch-anonymization',
            element: <SignOrdersBatchAnonymization />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: 'unauthorized',
        element: <Unauthorized />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

const routes = applyRequiredGroupsToRoutes(routeDefinitions);
export default routes;
